<template>
<div class="configuratorContainer" style="width:100%; height:100%">
    <v-stage
      ref="stage"
      :config="configKonva"
      @dragstart="handleDragstart"
      @dragend="handleDragend"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      @mousemove="handleMouseMove"
    >
      <v-layer ref="layer">
        <v-group :config="{draggable: true, x:10, y:10}">
          <v-image :config="{
            image: image,
            height:50,
            width:50
          }"/>
        <v-circle
          :config="{
            x: 16,
            y: 45,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }"
        />

        <v-circle
          :config="{
            x: 36,
            y: 45,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }"
        />
        </v-group>


        <v-group :config="{draggable: true, x:10, y:160}">
          <v-image :config="{
            image: imageMotor,
            height:50,
            width:50
          }"/>
        <v-circle
          :config="{
            x: 18,
            y: 47,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }"
        />

        <v-circle
          :config="{
            x: 32,
            y: 47,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }"
        />
        </v-group>

        <v-group :config="{draggable: true, x:0, y:70}">
          <v-image :config="{
            image: imageMicrobit,
            height:70,
            width:70,
          }"/>
        <v-circle
          :config="{
            x: 25,
            y: 70,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }"
        />

        <v-circle
          :config="{
            x: 45,
            y: 70,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }"
        />
        </v-group>

        <v-line
          v-for="line in connections"
          :key="line.id"
          :config="{
            stroke: 'black',
            points: line.points
          }"
        />
      </v-layer>
      <v-layer ref="dragLayer"></v-layer>
    </v-stage>
  </div>
 </template>

<script>
import Konva from "konva";
const width = window.innerWidth;
const height = window.innerHeight;

//let vm = {};
function generateTargets() {
  const circles = [];
  for (var i = 0; i < 10; i++) {
    circles.push({
      x: width * Math.random(),
      y: height * Math.random(),
      id: i
    });
  }
  return circles;
}
export default {
  data() {
    return {
      stageSize: {
        width: width,
        height: height
      },
      image:null,
      imageMicrobit: null,
      imageMotor: null,
      targets: generateTargets(),
      connections: [],
      drawningLine: false,

      list: [
      {
        id:1,
        x:50,
        y:50
      },
      {
        id:2,
        x:50,
        y:125
      },
      {
        id:3,
        x:50,
        y:200
      }



      ],
      dragItemId: null,
      configKonva: {
        width:150,
        height:150
      }
    };
  },
  methods: {
    handleDragstart(e) {
      // save drag element:
      this.dragItemId = e.target.id();
      // move current element to the top:
      //const item = this.list.find(i => i.id === this.dragItemId);
      //const index = this.list.indexOf(item);
      //this.list.splice(index, 1);
      //this.list.push(item);
    },
    handleDragend() {

      this.dragItemId = null;
    },
    handleMouseDown(e) {
      const onCircle = e.target instanceof Konva.Circle;
      if (!onCircle) {
        return;
      }
      this.drawningLine = true;
      this.connections.push({
        id: Date.now(),
        points: [e.target.x(), e.target.y()]
      });
    },
    handleMouseMove(e) {
      if (!this.drawningLine) {
        return;
      }
      const pos = e.target.getStage().getPointerPosition();
      const lastLine = this.connections[this.connections.length - 1];
      lastLine.points = [lastLine.points[0], lastLine.points[1], pos.x, pos.y];
    },
    handleMouseUp(e) {
      const onCircle = e.target instanceof Konva.Circle;
      if (!onCircle) {
        return;
      }
      this.drawningLine = false;
      const lastLine = this.connections[this.connections.length - 1];
      lastLine.points = [
        lastLine.points[0],
        lastLine.points[1],
        e.target.x(),
        e.target.y()
      ];
    }
  },
  mounted() {

    const configuratorContainer = document.querySelector(".configuratorContainer");
    const observer = new ResizeObserver(() => {
      this.configKonva.width = configuratorContainer.offsetWidth;
      this.configKonva.height = configuratorContainer.offsetHeight;

    });
    observer.observe(configuratorContainer);

  },
  created() {
  /*
        const list = [
"https://editor.tinkercad.com/components/thumbnails/motorDCArduinoKit.png",
"https://editor.tinkercad.com/components/thumbnails/photoResistor.png",
"https://editor.tinkercad.com/components/thumbnails/piezo.png",
"https://editor.tinkercad.com/components/thumbnails/starterMicrobitLight.png",
"https://editor.tinkercad.com/components/thumbnails/sensorUltrasonicDistance.png"
    ]
*/
    const image = new window.Image();
    const imageMicrobit = new window.Image();
    const imageMotor = new window.Image();
    image.src = "https://editor.tinkercad.com/components/thumbnails/piezo.png";
    imageMicrobit.src = "https://editor.tinkercad.com/components/thumbnails/starterMicrobitLight.png";
    imageMotor.src = "https://editor.tinkercad.com/components/thumbnails/motorDCArduinoKit.png";

    image.onload = () => {
      // set image only when it is loaded
      this.image = image;
    };
    imageMicrobit.onload = () => {
      // set image only when it is loaded
      this.imageMicrobit = imageMicrobit;
    };
    imageMotor.onload = () => {
      // set image only when it is loaded
      this.imageMotor = imageMotor;
    };

  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.configuratorContainer {
}

</style>
