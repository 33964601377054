var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"configuratorContainer",staticStyle:{"width":"100%","height":"100%"}},[_c('v-stage',{ref:"stage",attrs:{"config":_vm.configKonva},on:{"dragstart":_vm.handleDragstart,"dragend":_vm.handleDragend,"mousedown":_vm.handleMouseDown,"mouseup":_vm.handleMouseUp,"mousemove":_vm.handleMouseMove}},[_c('v-layer',{ref:"layer"},[_c('v-group',{attrs:{"config":{draggable: true, x:10, y:10}}},[_c('v-image',{attrs:{"config":{
            image: _vm.image,
            height:50,
            width:50
          }}}),_c('v-circle',{attrs:{"config":{
            x: 16,
            y: 45,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }}}),_c('v-circle',{attrs:{"config":{
            x: 36,
            y: 45,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }}})],1),_c('v-group',{attrs:{"config":{draggable: true, x:10, y:160}}},[_c('v-image',{attrs:{"config":{
            image: _vm.imageMotor,
            height:50,
            width:50
          }}}),_c('v-circle',{attrs:{"config":{
            x: 18,
            y: 47,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }}}),_c('v-circle',{attrs:{"config":{
            x: 32,
            y: 47,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }}})],1),_c('v-group',{attrs:{"config":{draggable: true, x:0, y:70}}},[_c('v-image',{attrs:{"config":{
            image: _vm.imageMicrobit,
            height:70,
            width:70,
          }}}),_c('v-circle',{attrs:{"config":{
            x: 25,
            y: 70,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }}}),_c('v-circle',{attrs:{"config":{
            x: 45,
            y: 70,
            radius: 5,
            stroke: 'black',
            fill: 'green'
          }}})],1),_vm._l((_vm.connections),function(line){return _c('v-line',{key:line.id,attrs:{"config":{
            stroke: 'black',
            points: line.points
          }}})})],2),_c('v-layer',{ref:"dragLayer"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }