var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-rect',{attrs:{"config":{
          x:_vm.xpos,
          y:_vm.ypos,
          height:20,
          width:70,
          fill:'blue',
          cornerRadius:5
        }}}),_c('v-rect',{attrs:{"config":{
          x:_vm.xpos+2,
          y:_vm.ypos+2,
          height:16,
          width:66,
          cornerRadius:3,
          strokeWith: 0.7,
          stroke: 'white'
        }}}),_c('v-text',{attrs:{"config":{
          text: _vm.title ,
          x:_vm.xpos+4,
          y:_vm.ypos+4,
          height:20,
          width:70,
          fill:'white'
        }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }