<template>
<div style="padding:10px; width:50%; position: absolute;
bottom: 0;
right: 0;">
<h4>{{ title }} </h4>
<p>{{ description }} </p>
</div>
</template>

<script >


export default {
  data() {
    return {
      title: "",
      description: ""
    }
  },
  mounted() {
    this.title = this.$store.state.params.title || "Programmeer de trein"
    this.description = this.$store.state.params.description || "Laat de trein zelfstandig rijden door de juiste hardware (sensoren en actuatoren) en software (code voor een toestandsdiagram) te maken."
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
