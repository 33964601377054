<template>
  <div style="overflow: auto; position: absolute; bottom: 0px; left: 0px;">
    <p v-if="isConnected">We're connected to the server!</p>
    <p v-else>not connected 😢</p>
    <p>Message from server: "{{socketMessage}}"</p>
    <button v-on:click="pingServer()">Ping Server</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isConnected: false,
      socketMessage: ''
    }
  },

  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      this.socketMessage = data
    }
  },

  methods: {
    pingServer() {
      // Send the "pingServer" event to the server.
      console.log ("ping");
      this.$socket.emit('pingServer', 'PING!')
    }
  }
}
</script>
