<template>
  <div >
        <v-rect  :config="{
          x:xpos,
          y:ypos,
          height:20,
          width:70,
          fill:'blue',
          cornerRadius:5
        }" >
        </v-rect>
        <v-rect  :config="{
          x:xpos+2,
          y:ypos+2,
          height:16,
          width:66,
          cornerRadius:3,
          strokeWith: 0.7,
          stroke: 'white'
        }" >
        </v-rect>
        <v-text  :config="{
          text: title ,
          x:xpos+4,
          y:ypos+4,
          height:20,
          width:70,
          fill:'white'
        }" >
        </v-text>
</div>
</template>

<script>
export default {
  name: 'City',
  props: {
    xpos: {
      type: Number,
      default: 100
},
    ypos: {
      type: Number,
      default: 100
   },
   title : {
      type: String,
      default : "not defined"
   }
   },
  data(){
    return {
      workspace: null
    }
  }
}
</script>
