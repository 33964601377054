var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"demoContainer",staticClass:"demoContainer"},[_c('v-stage',{ref:"stage",attrs:{"config":_vm.configStage}},[_c('v-layer',{ref:"layer"},[_c('v-rect',{ref:"ground",attrs:{"config":{
          x:0,
          y:_vm.configStage.height-50,
          height:50,
          width: _vm.configStage.width,
          fill: 'green'
        }}}),_c('Rails',{attrs:{"xpos":0,"ypos":this.configStage.height-50,"width":this.configStage.width}})],1),_c('v-layer',{ref:"layer"},[_c('City',{attrs:{"xpos":5,"ypos":this.configStage.height-150,"title":"Hilversum"}}),(_vm.isTypeTussenstop)?_c('City',{attrs:{"xpos":this.configStage.width*0.3,"ypos":this.configStage.height-150,"title":"Bussum"}}):_vm._e(),_c('City',{attrs:{"xpos":this.configStage.width-80,"ypos":this.configStage.height-150,"title":"Amsterdam"}})],1),_c('v-layer',{ref:"layer"},[_c('v-image',{ref:"trein",attrs:{"config":{
            image: _vm.trainImage,
            x: 10,
            y: _vm.configStage.height-130,
            height: 100,
            width: 100
        }}}),(_vm.isTypeKoe)?_c('v-image',{ref:"cow",attrs:{"config":{
          x:300,
          y:_vm.configStage.height-100,
          height:60,
          width:60,
          image: _vm.imageCow

        }}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }