<template>
  <div >
    <div v-for="index in Math.floor(width/20)" :key="index">
        <v-rect   :config="{
          x:xpos + index*20,
          y:ypos,
          height:5,
          width:20,
          fill:'black',
        }" >
        </v-rect>
        <v-rect  :config="{
          x:xpos + index*20+5,
          y:ypos+5,
          height:5,
          width:10,
          fill: 'brown',
        }" >
        </v-rect>
    </div>

</div>
</template>

<script>
export default {
  name: 'City',
  props: {
    xpos: {
      type: Number,
      default: 0
},
    ypos: {
      type: Number,
      default: 100
   },
    width: {
      type: Number,
      default: 100
   }
   },
  data(){
    return {
      workspace: null
    }
  }
}
</script>

