<template>
<div style="padding:10px; width:50%; position: absolute;
top: 0;
right: 0;">
<TeamMembers :items="items" />
</div>
</template>

<script >
import TeamMembers from '../components/TeamMembers.vue'


export default {
  components: {
    TeamMembers
  },
  data() {
    return {
items:[{
id: 1,
      name: 'Jasper',
      selected: false
      },
      {
id: 2,
      name: 'Tim',
      selected: false
    }]
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
