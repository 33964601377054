import Vue from 'vue'
import App from './App.vue'
import 'es6-promise/auto';
import Vuex from 'vuex'
import VueSocketIO from 'vue-socket.io'
import VueKonva from 'vue-konva';

//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//import { EventDispatcher } from './components/globals.js';

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

//curl "https://peaceful-hamlet-05175.herokuapp.com/socket.io/?EIO=4&transport=polling"


Vue.config.productionTip = false
  //Add unimported components to ignore list to prevent warnings.
Vue.config.ignoredElements = ['field', 'block', 'category', 'xml', 'mutation',
  'value', 'sep'
]


Vue.use(Vuex)



const params = {
  "choiceType": "VanNaar",
  "title": "Van Hilversum naar Amsterdam",
  "description": "Laat de trein vanzelf rijden van Hilversum naar Amsterdam door je juiste hardware te koppelen en de juiste programmacode te schrijven.",
  "behaviour": {
    "enableChat": true,
    "enableTeam": true
  }


  /*
  const params = {
    "choiceType": "Tussenstop",
    "title": "Stop bij Bussum",
    "description": "Laat de trein vanzelf rijden van Hilversum naar Amsterdam maar stop ook even in Bussum.",
    "behaviour": {
      "enableChat": true,
      "enableTeam": true
    }

  */
  /*
  const params = {
    "choiceType": "Koe",
    "title": "Pas op voor de koe!",
    "description": "Laat de trein vanzelf rijden maar pas op voor loslopende koeien.",
    "behaviour": {
      "enableChat": true,
      "enableTeam": true
    }
    */
};


const store = new Vuex.Store({

    state: {
      isConnected: false,
      socketMessage: '',
      code: "",
      count: 0,
      params: params
    },


    mutations: {
      SOCKET_CONNECT(state) {
          state.isConnected = true;
        },

        SOCKET_DISCONNECT(state) {
          state.isConnected = false;
        },

        SOCKET_MESSAGECHANNEL(state, message) {
          state.socketMessage = message
        }

    }
  })
  //curl "https://peaceful-hamlet-05175.herokuapp.com/socket.io/?EIO=4&transport=polling"
  //Vue.use(BootstrapVue)
  //Vue.use(IconsPlugin)

Vue.use(new VueSocketIO({
  debug: true,
  connection: 'https://peaceful-hamlet-05175.herokuapp.com',
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
  options: {
    path: "/"
  } //Optional options
}))

Vue.use(VueKonva);

new Vue({
  store: store,
  components: {
    App
  },
  render: h => h(App),
}).$mount(".h5p-train")
