<template>
<div style="padding:10px; width:45%; height:50%; overflow:auto;  position: absolute;
top: 0;
left: 0;">
<TimelineList :items="items" />
</div>
</template>

<script >
import TimelineList from '../components/TimelineList.vue'


export default {
  components: {
    TimelineList
  },
  data() {
    return {
items:[{
id: 1,
      name: 'Trein 1234 kan vertrekken 1',
      date: '12 juni 2021',
      time: '12:01',
      selected: false
      }, {
id: 2,
      name: '11:34 Spoor is.',
      date: '11 juni 2021',
      time: '16:01',
      selected: false
      }, {
id: 3,
      name: '11:15 U kunt vertrekken op spoor 2',
      date: '10 juni 2021',
      time: '9:01',
      selected: false
      },{
id: 4,
      name: '11:15 U kunt vertrekken op spoor 2',
      date: '9 juni 2021',
      time: '9:01',
      selected: false
      }]

    }

  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
