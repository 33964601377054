<template>
 <div class ="TeamMembersListPanel">
    <div class="container" style="margin:0; overflow:auto; height:100%">
      <div class="row">
        <div class="">
          <h4>Team</h4>
          <ul class="timeline">
            <li v-for="item in items" :key="item.id">
              <p>
                    {{ item.name }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props : ['items']
 }
</script>

<style>
.TeamMembersListPanel { text-align: left;}
ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}
</style>
