var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((Math.floor(_vm.width/20)),function(index){return _c('div',{key:index},[_c('v-rect',{attrs:{"config":{
          x:_vm.xpos + index*20,
          y:_vm.ypos,
          height:5,
          width:20,
          fill:'black',
        }}}),_c('v-rect',{attrs:{"config":{
          x:_vm.xpos + index*20+5,
          y:_vm.ypos+5,
          height:5,
          width:10,
          fill: 'brown',
        }}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }